import React from "react";
import "./LandingPage.css";

export default function LandingPage() {
	return (
		<div className="landing_page">
			<h3>Out-source the best of the best</h3>
			<h1>Build your Dream PC with Ease</h1>
		</div>
	);
}
