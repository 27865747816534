import React from "react";
import "./Footer.css";
import { HashLink as Link } from "react-router-hash-link";

export default function Footer() {
	return (
		<div className="footer_section">
			<div className="upper_footer">
				<Link to="/">
					<div className="logo">
						<img alt="logo_pic" src="/media/bnb_logo.png" className="logo_img" />
						<div className="logo_text"> buildNbox </div>
					</div>
				</Link>

				<div className="shop_section">
					<div className="logo_text"> Shop </div>
					<ul>
						<li>
							<Link to="/store#CPU">CPU</Link>
						</li>
						<li>
							<Link to="/store#Motherboard">Motherboard</Link>
						</li>
						<li>
							<Link to="/store#SSD">Storage</Link>
						</li>
						<li>
							<Link to="/store#RAM">RAM</Link>
						</li>
					</ul>
				</div>

				<div className="reachus_section">
					<div className="logo_text"> Reach Us</div>
					<ul>
						<li>
							<a href="https://www.instagram.com/buildnbox/" target="_blank" rel="noopener noreferrer"> Instagram </a>
						</li>
						<li>
							<a href="mailto:buildandbox@gmail.com"> Email </a>
						</li>
					</ul>
				</div>

				<div className="company_section">
					<div className="logo_text"> Company </div>
					<ul>
						<li>
							<Link to="/company#au">About&nbsp;Us</Link>
						</li>
						<li>
							<Link to="/company#pp">Privacy&nbsp;Policy</Link>
						</li>
						<li>
							<Link to="/company#am">Affiliate&nbsp;Marketing</Link>
						</li>
					</ul>
				</div>
			</div>
			<p>© 2023, buildNbox.com</p>
		</div>
	);
}
