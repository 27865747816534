const computerParts = [
	{
		partType: "CPU",
		parts: [
			{
				partName: "Intel Core i5 10400F",
				partPrice: 300,
				partImg: "/Database/CPU/Core i5 10400F/61cKmFhEqTL._AC_SL1500_.jpg",
				partURL:
					"https://www.amazon.ca/i5-10400F-Desktop-Processor-Without-Graphics/dp/B086MHSTWN/ref=sr_1_4?crid=198MM9300MDBK&keywords=Intel+Core+i5+10400&qid=1652727419&s=electronics&sprefix=intel+core+i5+10400%2Celectronics%2C97&sr=1-4",
			},
			{
				partName: "Intel Core i5 11600K",
				partPrice: 300,
				partImg: "/Database/CPU/Core i5 11600K/61pZ7RxQQtL._AC_SL1500_.jpg",
				partURL:
					"https://www.amazon.ca/i5-11600K-Desktop-Processor-Unlocked-LGA1200/dp/B08X67YZBL/ref=sr_1_3?crid=2F73EE6TPOO4E&keywords=Intel+Core+i5+11600&qid=1652727476&s=electronics&sprefix=intel+core+i5+11600%2Celectronics%2C63&sr=1-3",
			},
			{
				partName: "Intel Core i7 12700K",
				partPrice: 300,
				partImg: "/Database/CPU/Core i7 12700K/51AbfAFuhLL._AC_SL1038_.jpg",
				partURL: "https://www.amazon.ca/Intel-i7-12700K-Desktop-Processor-Unlocked/dp/B09FXNVDBJ/ref=sr_1_23?keywords=intel+cpu&qid=1652724777&s=pc&sr=1-23",
			},
			{
				partName: "Intel Core i9 10900K",
				partPrice: 300,
				partImg: "/Database/CPU/Core i9 10900K/61aMmen0cFL._AC_SL1099_.jpg",
				partURL:
					"https://www.amazon.ca/Intel-i9-10900K-Desktop-Processor-Unlocked/dp/B086MHSTVD/ref=sr_1_4?crid=12CD71E9A6YS4&keywords=Intel+Core+i9&qid=1652726900&s=electronics&sprefix=intel+core+i9%2Celectronics%2C145&sr=1-4",
			},
			{
				partName: "Intel Core i9 11900K",
				partPrice: 300,
				partImg: "/Database/CPU/Core i9 11900K/41jGx-EgaJL._AC_.jpg",
				partURL:
					"https://www.amazon.ca/Intel-i9-11900K-Desktop-Processor-Unlocked/dp/B08X6PPTTH/ref=sr_1_5?crid=12CD71E9A6YS4&keywords=Intel+Core+i9&qid=1652726900&s=electronics&sprefix=intel+core+i9%2Celectronics%2C145&sr=1-5",
			},
			{
				partName: "AMD Ryzen 5 5600",
				partPrice: 300,
				partImg: "/Database/CPU/Ryzen 5 5600/51esLVqZB0L._AC_SL1384_.jpg",
				partURL:
					"https://www.amazon.ca/AMD-5600-Wraith-Stealth-Cooler/dp/B09VCHR1VH/ref=sr_1_2?crid=MEAJIUU17R3J&keywords=Ryzen+5600&qid=1652727661&s=electronics&sprefix=ryzen+5600%2Celectronics%2C97&sr=1-2",
			},
			{
				partName: "AMD Ryzen 7 5700G",
				partPrice: 300,
				partImg: "/Database/CPU/Ryzen 7 5700G/51p5BjKPc1L._AC_SL1395_.jpg",
				partURL:
					"https://www.amazon.ca/AMD-Ryzen-5700G-16-Thread-Processor/dp/B091J3NYVF/ref=sr_1_1?crid=2TLX0NQBH70GI&keywords=Ryzen+5700g&qid=1652727510&s=electronics&sprefix=ryzen+5700g%2Celectronics%2C79&sr=1-1",
			},
			{
				partName: "AMD Ryzen 7 5800X",
				partPrice: 300,
				partImg: "/Database/CPU/Ryzen 7 5800X/61DYLoyNRWL._AC_SL1384_.jpg",
				partURL:
					"https://www.amazon.ca/DANIPEW-Sand-Man-Cotton-Performance-T-Shirt/dp/B0815XFSGK/ref=sr_1_1?crid=K47LJHHH4FSR&keywords=Ryzen+5800x&qid=1652727623&s=electronics&sprefix=ryzen+5800x%2Celectronics%2C70&sr=1-1",
			},
			{
				partName: "AMD Ryzen 9 5900X",
				partPrice: 300,
				partImg: "/Database/CPU/Ryzen 9 5900X/61gMd1izD5L._AC_SL1500_.jpg",
				partURL:
					"https://www.amazon.ca/DANIPEW-Sand-Man-Cotton-Performance-T-Shirt/dp/B08164VTWH/ref=sr_1_1?crid=13UNKQR9EZKFP&keywords=Ryzen+5900x&qid=1652727697&s=electronics&sprefix=ryzen+5900x%2Celectronics%2C83&sr=1-1",
			},
		],
	},
	{
		partType: "Motherboard",
		parts: [
			{
				partName: "Asus Prime B560M",
				partPrice: 250,
				partImg: "/Database/Motherboard/ASUS Prime B560M/91ZmJMlAZZL._AC_SL1500_.jpg",
				partURL: "https://www.amazon.ca/Prime-B560M-Intel-Micro-Motherboard/dp/B08W726FGL/ref=sr_1_1?crid=ZV60724A1X79&keywords=LGA+1200&qid=1653504435&sprefix=lga+1200%2Caps%2C117&sr=8-1",
			},
			{
				partName: "ASUS Prime b660M mATX",
				partPrice: 150,
				partImg: "/Database/Motherboard/ASUS Prime B660M mATX/71DAneTyrcL._AC_SL1500_.jpg",
				partURL:
					"https://www.amazon.ca/Prime-B660M-D4-Motherboard-Type-C/dp/B09NWF1JQC/ref=sr_1_1?crid=354I6LMRY8LA8&keywords=1700+motherboard&qid=1653505821&sprefix=1700+motherboard%2Caps%2C77&sr=8-1",
			},
			{
				partName: "ASUS Prime Z590-A",
				partPrice: 300,
				partImg: "/Database/Motherboard/ASUS Prime Z590-A/81CGEFea8AL._AC_SL1500_.jpg",
				partURL:
					"https://www.amazon.ca/Intel%C2%AE11th-Motherboard-Stages-ThunderboltTM-Lighting/dp/B08SYWZW4S/ref=sr_1_8?crid=5HZXW5JWX2RR&keywords=intel+motherboard+10th+gen&qid=1653442747&sprefix=intel+motherboard+10th+gen%2Caps%2C85&sr=8-8",
			},
			{
				partName: "ASUS ROG Strix X570-I mini-ITX",
				partPrice: 300,
				partImg: "/Database/Motherboard/ASUS ROG Strix X570-I mini-ITX/81NtAz0WkFL._AC_SL1500_.jpg",
				partURL:
					"https://www.amazon.ca/ROG-Strix-X570-I-Gaming-motherboard/dp/B07Y2PZTQ5/ref=sr_1_4?crid=AEYP33GN9O9I&keywords=x570%2Bmotherboard&qid=1653442490&sprefix=x570%2Bmotherboard%2Caps%2C64&sr=8-4&th=1",
			},
			{
				partName: "ASUS TUFF Gaming B450-Plus II",
				partPrice: 300,
				partImg: "/Database/Motherboard/ASUS TUFF Gaming B450-Plus II/71pt8B1MgcL._AC_SL1500_.jpg",
				partURL:
					"https://www.amazon.ca/TUF-B450-PLUS-II-Motherboard-Cancelling/dp/B08LCP4N8Q/ref=sr_1_2?crid=1EJZ4487OMKNE&keywords=b450+motherboard&qid=1653441329&sprefix=b450+motherboard%2Caps%2C87&sr=8-2",
			},
			{
				partName: "Gigabyte B450 AORUS Elite V2",
				partPrice: 300,
				partImg: "/Database/Motherboard/Gigabyte B450 AORUS Elite V2/71tlDK29xFL._AC_SL1500_.jpg",
				partURL:
					"https://www.amazon.ca/Gigabyte-B450-AORUS-V2-Motherboard/dp/B08LG2JC4B/ref=sr_1_1?crid=2K06DZXFX0MW1&keywords=b450%2Bmotherboard&qid=1652929162&sprefix=b450%2Bmotherboard%2Caps%2C99&sr=8-1&th=1",
			},
			{
				partName: "Gigabyte B660 Gaming X",
				partPrice: 300,
				partImg: "/Database/Motherboard/Gigabyte B660 Gaming X/61tp3eLcP6L._AC_SL1500_.jpg",
				partURL:
					"https://www.amazon.ca/GIGABYTE-B660-AX-DDR4-Motherboard/dp/B09SVZRM13/ref=sr_1_8?crid=354I6LMRY8LA8&keywords=1700+motherboard&qid=1653505980&sprefix=1700+motherboard%2Caps%2C77&sr=8-8",
			},
			{
				partName: "MSI MAG X570 Tomahawk",
				partPrice: 300,
				partImg: "/Database/Motherboard/MSI MAG X570 Tomahawk/81Fs+Oe2bhL._AC_SL1500_.jpg",
				partURL:
					"https://www.amazon.ca/MSI-MAG-X570-Tomahawk-WiFi/dp/B0892R8PDJ/ref=sr_1_1?crid=AEYP33GN9O9I&keywords=x570+motherboard&qid=1653441920&sprefix=x570+motherboard%2Caps%2C64&sr=8-1",
			},
			{
				partName: "MSI MPG B550 Gaming Plus",
				partPrice: 300,
				partImg: "/Database/Motherboard/MSI MPG B550 Gaming Plus/81kovaR1zQL._AC_SL1500_.jpg",
				partURL:
					"https://www.amazon.ca/MSI-MPG-B550-Gaming-Motherboard/dp/B089CRF5XV/ref=sr_1_15?crid=1EJZ4487OMKNE&keywords=b450+motherboard&qid=1653441591&sprefix=b450+motherboard%2Caps%2C87&sr=8-15",
			},
			{
				partName: "MSI MPG Z490 Gaming Plus",
				partPrice: 300,
				partImg: "/Database/Motherboard/MSI MPG Z490 Gaming Plus/71uDaoKYC2L._AC_SL1500_.jpg",
				partURL: "https://www.amazon.ca/MSI-Gaming-Motherboard-Socket-Mystic/dp/B0876HBFVC",
			},
			{
				partName: "MSI PRO Z690-A ATX",
				partPrice: 300,
				partImg: "/Database/Motherboard/MSI PRO Z690-A ATX/61IZtUCyOzL._AC_SL1500_.jpg",
				partURL: "https://www.amazon.ca/PRO-Z690-DDR4-Motherboard-ATX/dp/B09GLD6LYW/ref=sr_1_1?keywords=msi+pro+z690-a&qid=1653505639&sprefix=msi+pro%2Caps%2C84&sr=8-1",
			},
		],
	},
	{
		partType: "SSD",
		parts: [
			{
				partName: "Crucial P2 1TB",
				partPrice: 250,
				partImg: "/Database/Storage/Crucial P2 1TB/61J+ROo+7WL._AC_SL1500_.jpg",
				partURL: "https://www.amazon.ca/Crucial-NAND-NVMe-PCIe-2400MB/dp/B089DNM8LR/ref=sr_1_50?crid=LUD71IOWZ6X8&keywords=ssd&qid=1653509912&sprefix=ssd%2Caps%2C91&sr=8-50&th=1",
			},
			{
				partName: "Samsung 970 EVO plus 500GB",
				partPrice: 250,
				partImg: "/Database/Storage/Samsung 970 EVO plus 500GB/31+nEf9FufL._AC_.jpg",
				partURL: "https://www.amazon.ca/Samsung-970-EVO-Plus-MZ-V7S500B/dp/B07M7Q21N7/ref=sr_1_31?crid=LUD71IOWZ6X8&keywords=ssd&qid=1653509912&sprefix=ssd%2Caps%2C91&sr=8-31&th=1",
			},
			{
				partName: "SanDisk Internal SSD 1TB",
				partPrice: 250,
				partImg: "/Database/Storage/SanDisk Internal SSD 1TB/71BfJwXt3bL._AC_SL1500_.jpg",
				partURL: "https://www.amazon.ca/SanDisk-SSD-Plus-Internal-SDSSDA-1T00-G26/dp/B07D998212/ref=sr_1_19?crid=LUD71IOWZ6X8&keywords=ssd&qid=1653507959&sprefix=ssd%2Caps%2C91&sr=8-19&th=1",
			},
			{
				partName: "WD Black SN770 1TB",
				partPrice: 250,
				partImg: "/Database/Storage/WD Black SN770 1TB/51vNa5DEr4L._AC_SL1100_.jpg",
				partURL: "https://www.amazon.ca/WD_BLACK-SN770-Internal-Gaming-Solid/dp/B09QV692XY/ref=sr_1_20?crid=LUD71IOWZ6X8&keywords=ssd&qid=1653509912&sprefix=ssd%2Caps%2C91&sr=8-20",
			},
		],
	},
	{
		partType: "RAM",
		parts: [
			{
				partName: "Corsair Vengeance LPX 16GB (2 X 8GB) DDR4 3600",
				partPrice: 250,
				partImg: "/Database/RAM/Corsair Vengeance LPX 16GB (2 X 8GB) DDR4 3600/51cbs7SW8JS._AC_SL1200_.jpg",
				partURL:
					"https://www.amazon.ca/Corsair-Vengeance-PC4-28800-Desktop-Memory/dp/B07RM39V5F/ref=sr_1_15?crid=22C4VIQM2WB77&keywords=ram&qid=1653506900&sprefix=ram%2Caps%2C105&sr=8-15&th=1",
			},
			{
				partName: "G.SKILL F4-3200C16D-16GTZR Trident Z RGB Series 16GB",
				partPrice: 250,
				partImg: "/Database/RAM/G.SKILL F4-3200C16D-16GTZR Trident Z RGB Series 16GB, 288-Pin SDRAM DDR4-3200MHz/51pXEviytaL._AC_.jpg",
				partURL:
					"https://www.amazon.ca/G-SKILL-F4-3200C16D-16GTZR-Trident-288-Pin-DDR4-3200MHz/dp/B01MTDEYHU/ref=sr_1_18?crid=22C4VIQM2WB77&keywords=ram&qid=1653506674&sprefix=ram%2Caps%2C105&sr=8-18",
			},
			{
				partName: "G.SKILL Trident Z Neo Series RGB DDR4 4000MHz 32GB(16GBx2)",
				partPrice: 250,
				partImg: "/Database/RAM/G.SKILL Trident Z Neo Series RGB DDR4 4000MHz 32GB(16GBx2)/61BT+FktxDL._AC_SL1100_.jpg",
				partURL: "https://www.amazon.ca/G-SKILL-Trident-4000MHz-16GBx2-Memory/dp/B08TQH3JDQ/ref=sr_1_32?crid=22C4VIQM2WB77&keywords=ram&qid=1653534403&sprefix=ram%2Caps%2C105&sr=8-32",
			},
			{
				partName: "GSkill Ripjaws V 32GB 3200",
				partPrice: 250,
				partImg: "/Database/RAM/GSkill Ripjaws V 32GB 3200/61HmhEDI7RL._AC_SL1280_.jpg",
				partURL:
					"https://www.amazon.ca/G-SKILL-Ripjaws-PC4-28800-3600MHz-F4-3600C19D-32GVRB/dp/B07FSWN49R/ref=sr_1_20?crid=22C4VIQM2WB77&keywords=ram&qid=1653506900&sprefix=ram%2Caps%2C105&sr=8-20",
			},
			{
				partName: "Patriot Viper Steel DDR4 16GB (2 x 8GB) 3200MHz",
				partPrice: 250,
				partImg: "/Database/RAM/Patriot Viper Steel DDR4 16GB (2 x 8GB) 3200MHz/81d7rlyjtVL._AC_SL1500_.jpg",
				partURL: "https://www.amazon.ca/Viper-Steel-3200MHz-Performance-Memory/dp/B07KXMWHBY/ref=sr_1_38?crid=22C4VIQM2WB77&keywords=ram&qid=1653506900&sprefix=ram%2Caps%2C105&sr=8-38&th=1",
			},
			{
				partName: "XPG D50 RGB DDR4 16GB (2x8GB) 3200MHz",
				partPrice: 250,
				partImg: "/Database/RAM/XPG D50 RGB DDR4 16GB (2x8GB) 3200MHz/51jy1O16y-S._AC_.jpg",
				partURL: "https://www.amazon.ca/XPG-D50-3200MHz-PC4-25600-288-Pins/dp/B092G5Y6QP/ref=sr_1_17?crid=22C4VIQM2WB77&keywords=ram&qid=1653506900&sprefix=ram%2Caps%2C105&sr=8-17",
			},
		],
	},
];

export default computerParts;
