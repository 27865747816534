import React from "react";
import "./ShopSection.css";

const ShopSection = (props) => {
	// let projectID = "q9pnc9vs";
	// let dataset: "production";
	// let query = encodeURIComponent('*[name == "Motherboard"]{"partName":parts->partName}');
	// let URL = `https://${projectID}.api.sanity.io/v2021-10-21/data/query/${dataset}?query=${query}`;

	return (
		<div className="part_card">
			<div className="part_card_upper">
				<a href={props.url}>
					<div className="part_img">
						<img alt="part_img" src={props.img} />
					</div>
					<div className="part_name">{props.name}</div>
					{/* <div className="part_price">Price: ${props.price}</div> */}
				</a>
			</div>
			<div className="part_link">
				{/* Commented price property due to insufficient product data provided */}
				{/* <div className="part_price">Price: ${props.price}</div> */}
				<a href={props.url}>
					<button>View&nbsp;on&nbsp;Amazon</button>
				</a>
			</div>
		</div>
	);
};

export default ShopSection;
