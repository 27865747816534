import React from "react";
import "./Company.css";

const Company = () => {
	return (
		<div>
			<div className = "au" id="au">
				<h2>About Us</h2>
				<p>buildnbox is an online business catering to PC building enthusiasts. Our website serves as a platform to showcase and inform users about the latest computer parts and components available on Amazon. We provide valuable insights, expert reviews, and up-to-date information to assist users in making informed decisions for their PC builds.</p>
				<h3>Contact Information:</h3>
				<p>If you have any questions, concerns, or feedback regarding our website, please don't hesitate to reach out to us. You can contact us through the contact form on our website or via email at buildandbox@gmail.com</p>
			</div>
			<div className = "pp" id="pp">
				<h2>Privacy Policy</h2>
				<p>At buildNbox we value the privacy of our visitors and users. This Privacy Policy outlines the types of personal information we collect and how it is used, as well as the measures we take to ensure its protection. By using our website, you consent to the practices described below.</p>
				<h3>Third-Party Disclosure</h3>
				<ul>
					<li>We may share non-personal information with third parties for marketing, advertising, or other uses.</li>
					<li>We participate in the Amazon Services LLC Associates Program, an affiliate marketing program. As a result, we earn advertising fees by linking to Amazon.com and affiliated sites. Please note that we have no control over the content or practices of these third-party websites and recommend reviewing their respective privacy policies.</li>
				</ul>
				<h3>Children's Privacy:</h3>
				<p>Our website is intended for a general audience and is not directed towards individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you believe that we have inadvertently collected personal information from a child, please contact us, and we will promptly remove the information from our records.</p>
				<h3>Changes to the Privacy Policy:</h3>
				<p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be reflected on this page, and we encourage you to review this policy periodically.</p>
			</div>
			<div className="am" id="am">
				<h2>Affiliate Marketing</h2>
				<p>At buildnbox, we follow an affiliate marketing approach. This means that we earn advertising fees by linking to Amazon.com and affiliated sites. When users click on the product links on our website and make a purchase, we may receive a commission from Amazon. This commission does not affect the price you pay for the product.</p>
				<h3>Disclaimer:</h3>
				<p>The information provided on buildNbox is for general informational purposes only. We strive to ensure the accuracy and reliability of the information presented. However, we make no warranties or representations of any kind, express or implied, regarding the completeness, accuracy, reliability, suitability, or availability of the information contained on our website.</p>
				<h3>Limitation of Liability:</h3>
				<p>buildNbox shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising</p>
			</div>
		</div>
	);
};

export default Company;
